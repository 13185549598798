import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

interface FooterProps {
  handlePrevious: () => void;
  handleNext: () => void;
  activeComponentIndex: number;
  loading: boolean;
  showPreviousButton: boolean;
  showNextButton: boolean;
}

function Footer(props: FooterProps) {
  const {
    handlePrevious,
    handleNext,
    loading,
    showPreviousButton,
    showNextButton,
    activeComponentIndex,
  } = props;

  const handleCancel = () => {
    window.location.href = import.meta.env.VITE_CANCEL_URL;
  };

  return (
    <div className="flex gap-3 pb-6">
      <button
        type="button"
        onClick={handlePrevious}
        className={`bg-[#4F46E5] px-3 py-2 border-[1px] border-[#4F46E5] rounded-md text-sm font-bold text-white ${
          activeComponentIndex === 0 ? 'hidden' : 'flex'
        } ${
          showPreviousButton ? 'opacity-50 cursor-not-allowed' : ''
        } items-center gap-2`}
        disabled={showPreviousButton}
      >
        <span>
          <AiOutlineArrowLeft />
        </span>
        Previous
      </button>
      <button
        type="button"
        className="px-3 py-2 border-[1px] border-[#D1D5DB] footer-button-shadow rounded-md text-sm font-bold text-[#374151]"
        disabled={loading}
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={handleNext}
        className={`bg-[#4F46E5] px-3 py-2 border-[1px] footer-button-shadow border-[#4F46E5] rounded-md text-sm font-bold text-white flex items-center gap-2 ${
          loading ? 'opacity-50 cursor-not-allowed' : ''
        } ${showNextButton ? 'flex' : 'hidden'}`}
        disabled={loading}
      >
        {loading ? (
          <span>Loading...</span>
        ) : (
          <>
            Next
            <span>
              <AiOutlineArrowRight />
            </span>
          </>
        )}
      </button>
    </div>
  );
}

export default Footer;
