import { useEffect, useState } from 'react';
import Header from '../../components/Header';
import PlanInformation from './components/planInformation/PlanInformation';
import PaymentInformation from './components/paymentInformation/PaymentInformation';
import { steps } from '../../constants';

function PaymentModule() {
  const [selectActiveComponent, setSelectActiveComponent] = useState('Choose your plan');
  const [activeComponentIndex, setActiveComponentIndex] = useState(0);
  const [selectedPriceId, setSelectedPriceId] = useState<string>('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (activeComponentIndex) {
      case 0:
        setTitle('Select a plan');
        break;
      case 1:
        setTitle('Make a payment');
        break;
      default:
        setTitle('');
    }
  }, [activeComponentIndex]);

  const handlePrevious = () => {
    const newIndex = activeComponentIndex - 1;
    setSelectActiveComponent(steps[newIndex].stepName);
    setActiveComponentIndex(newIndex);
  };

  const showActiveComponent = () => {
    switch (selectActiveComponent) {
      case 'Choose your plan':
        return (
          <PlanInformation
            activeComponentIndex={activeComponentIndex}
            setActiveComponentIndex={setActiveComponentIndex}
            setSelectActiveComponent={setSelectActiveComponent}
            setSelectedPriceId={setSelectedPriceId}
            handlePrevious={handlePrevious}
          />
        );
      case 'Payment information':
        return (
          <PaymentInformation
            selectedPriceId={selectedPriceId}
            handlePrevious={handlePrevious}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="px-8">
        <div className="py-6 lg:px-32 px-0">
          <h1 className="text-[#111827] text-2xl font-bold pb-6">{title}</h1>
          <div className="flex justify-evenly gap-8">
            {steps.map((step, index) => (
              <div key={step.id} className="w-full" role="button" tabIndex={0}>
                <div
                  className={`${
                    activeComponentIndex >= index
                      ? 'bg-[#0284C7]'
                      : 'bg-[#E5E7EB]'
                  } h-1 rounded-xl w-full mb-4`}
                />
                <h1
                  className={`${
                    selectActiveComponent === step.stepName
                      ? 'text-[#0284C7]'
                      : 'text-[#6B7280]'
                  } text-xs uppercase font-semibold`}
                >
                  step
                  {' '}
                  {index + 1}
                </h1>
                <p className="text-[#111827] text-base font-medium">
                  {step.stepName}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full">
          <div className="py-4">{showActiveComponent()}</div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModule;
