/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ConnectedProps, connect } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import CheckoutForm from './CheckoutForm';
import {
  cancelClientSecret,
  createClientSecret,
  getPriceList,
  updateClientSecret,
} from './paymentRedux/Payment.thunks';
import Footer from '../../../../components/Footer';

interface PaymentInformationProps extends ConnectedProps<typeof connector> {
  selectedPriceId: string;
  handlePrevious: () => void;
}

const mapStateToProps = (state: AppState) => ({
  paymentList: state.priceListReducer.priceList,
  isLoading: state.priceListReducer.loading,
  customerSecretData: state.priceListReducer.clientSecretData,
});

const mapDispatchToProps = {
  getAllPriceList: getPriceList,
  createCustomerSecret: createClientSecret,
  updateCustomerSecret: updateClientSecret,
  cancelCustomerSecret: cancelClientSecret,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

function PaymentInformation(props: PaymentInformationProps) {
  const {
    createCustomerSecret,
    customerSecretData,
    selectedPriceId,
    handlePrevious,
    updateCustomerSecret,
    cancelCustomerSecret,
  } = props;

  const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHKEY);

  const [isLoading, setIsLoading] = useState(true);

  const handleGoBack = () => {
    sessionStorage.setItem('previousPriceId', selectedPriceId);
    handlePrevious();
  };

  useEffect(() => {
    const clientId = sessionStorage.getItem('customerId');
    const subscriptionId = sessionStorage.getItem('subscriptionId');
    const itemId: any = sessionStorage.getItem('itemId');
    const previouslySelectedPriceId = sessionStorage.getItem('previousPriceId');
    if (clientId) {
      if (previouslySelectedPriceId !== selectedPriceId
        && subscriptionId !== null) {
        cancelCustomerSecret(subscriptionId).then(() => {
          sessionStorage.removeItem('subscriptionId');
          createCustomerSecret(clientId, selectedPriceId)
            .then((response) => {
              if (response.payload.data.statusCode) {
                toast.error(response.payload.data.message);
                setIsLoading(true);
              }
              sessionStorage.setItem('subscriptionId', response.payload.data.id);
              sessionStorage.setItem(
                'itemId',
                response.payload.data.items.data[0].id,
              );
            })
            .catch((error) => {
              toast.error(error);
            });
        }).catch((errorCancel:any) => {
          toast.error(errorCancel);
        });
      } else if (subscriptionId && itemId) {
        updateCustomerSecret(subscriptionId, itemId, selectedPriceId).then(({ payload: { data } }) => {
          if (data.statusCode) {
            toast.error(data.message);
            setIsLoading(true);
          } else {
            toast.success('Subscription Udpated');
          }
        }).catch((error) => toast.error(error));
      } else {
        createCustomerSecret(clientId, selectedPriceId)
          .then((response) => {
            sessionStorage.setItem('subscriptionId', response.payload.data.id);
            sessionStorage.setItem(
              'itemId',
              response.payload.data.items.data[0].id,
            );
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
  }, [createCustomerSecret, selectedPriceId, updateCustomerSecret]);

  // const getSubscriptionId = sessionStorage.getItem('subscriptionId');

  useEffect(() => {
    if (customerSecretData?.latest_invoice?.payment_intent?.client_secret) {
      setIsLoading(false);
    }
  }, [customerSecretData]);

  return (
    <div>
      <div>
        <h1 className="text-[#111827] text-5xl leading-[48px] font-extrabold">
          Payment Information
        </h1>
        {isLoading ? (
          <div className="py-10 flex w-full items-center justify-center">
            <RotatingLines
              strokeColor="#4F46E5"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible
            />
          </div>
        ) : (
          <div className="flex lg-flex-row flex-col gap-10 py-10">
            <div>
              <div>
                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret:
                      customerSecretData?.latest_invoice?.payment_intent
                        ?.client_secret,
                  }}
                >
                  <CheckoutForm />
                </Elements>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="border-t-[1px] border-[#E5E7EB] pt-6 w-full flex items-center justify-center">
          <Footer
            handlePrevious={handleGoBack}
            handleNext={() => {}}
            activeComponentIndex={2}
            loading={false}
            showPreviousButton={false}
            showNextButton={false}
          />
        </div>
      </div>
    </div>
  );
}

export default connector(PaymentInformation);
