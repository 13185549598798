import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ConnectedProps, connect } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getSelectedCustomerData } from '../../paymentModule/components/userInformation/customerRedux/Customer.thunks';
import { sendMail } from './sendMailRedux/sendMail.thunks';

Modal.setAppElement('#root');

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const mapStateToProps = (state: AppState) => ({
  customerData: state.CustomerListReducer.customerData,
  isLoading: state.CustomerListReducer.loading,
  mailLoading: state.sendMailReducer.loading,
});

const mapDispatchToProps = {
  getSelectedCustomer: getSelectedCustomerData,
  sendEMail: sendMail,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface Props extends ConnectedProps<typeof connector> {}

function ContactFormModal(props: Props) {
  const {
    openModal,
    setOpenModal,
    getSelectedCustomer,
    customerData,
    isLoading,
    mailLoading,
    sendEMail,
  } = props;

  const [commands, setCommands] = useState('');
  const [commandsError, setCommandsError] = useState(false);

  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      border: 'none',
      width: '50%',
    },
  };

  if (window.innerWidth <= 640) {
    customStyles.content.width = '80%';
  }

  useEffect(() => {
    const customerId: any = sessionStorage.getItem('customerId');
    getSelectedCustomer(customerId);
  }, [getSelectedCustomer]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (commands.trim() === '') {
      setCommandsError(true);
    } else {
      setCommandsError(false);
      const payload = {
        data: {
          personalizations: [
            {
              to: [
                {
                  email: import.meta.env.VITE_TO_EMAIL_ID,
                  name: import.meta.env.VITE_TO_EMAIL_NAME,
                },
                {
                  email: customerData?.customer?.email,
                  name: customerData?.customer?.name,
                },
              ],
            },
          ],
          from: {
            email: import.meta.env.VITE_FROM_EMAIL_ID,
            name: import.meta.env.VITE_FROM_EMAIL_NAME,
          },
          subject: `Enquiry on the Enterprise Plan for the organization ${customerData?.customer?.description}`,
          content: [
            {
              type: 'text/html',
              value: `Hi Team,
                     <p>We have a new Enquiry for the Enterprise from ${customerData?.customer?.description}<br>   
                      ContactName: <strong>${customerData?.customer?.name}</strong> <br>
                      ContactEmail: <strong>${customerData?.customer?.email}</strong><br>
                      MoreInformation: ${commands}</p>`,
            },
          ],
        },
      };

      sendEMail(payload)
        .then((response) => {
          if (response.payload.status === 200) {
            navigate('/mail/success');
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };

  // console.log('user data', customerData);

  return (
    <Modal
      isOpen={openModal}
      onRequestClose={() => setOpenModal(false)}
      style={customStyles}
      contentLabel="Contact Modal"
    >
      {isLoading ? (
        <div className="flex justify-center items-center py-20">
          <RotatingLines
            strokeColor="#4F46E5"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            visible
          />
        </div>
      ) : (
        <div>
          <div className="flex items-start">
            <h1 className="text-lg font-bold">Contact Form</h1>
          </div>
          <form className="w-full py-3" onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 w-full">
              <div className="flex flex-col items-start">
                <h1 className="text-[#374151] mb-1 text-sm font-medium">
                  Company
                </h1>
                <input
                  id="company"
                  name="company"
                  type="text"
                  className="border-[1px] w-full px-3 py-2 text-sm rounded-md"
                  value={customerData?.customer?.description}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-[#374151] mb-1 text-sm font-medium">
                  Name
                </h1>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="border-[1px] w-full px-3 py-2 text-sm rounded-md"
                  value={customerData?.customer?.name}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-[#374151] mb-1 text-sm font-medium">
                  Email
                </h1>
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="border-[1px] w-full px-3 py-2 text-sm rounded-md"
                  value={customerData?.customer?.email}
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start">
                <h1 className="text-[#374151] mb-1 text-sm font-medium">
                  Commands
                  {' '}
                  <span className="text-red-500">*</span>
                </h1>
                <textarea
                  id="commands"
                  name="email"
                  rows={8}
                  className={`border-[1px] w-full px-3 py-2 text-sm rounded-md ${
                    commandsError ? 'border-red-500' : ''
                  }`}
                  value={commands}
                  onChange={(e) => {
                    setCommands(e.target.value);
                    setCommandsError(false);
                  }}
                />
                {commandsError && (
                  <p
                    className={`error-message text-red-500 text-xs ${
                      commandsError ? 'opacity-100' : 'opacity-0'
                    } transition-opacity duration-300 ease-in-out`}
                  >
                    Commands is required.
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center gap-3 pt-6">
              <button
                type="button"
                className="w-full border-[1px] border-[#4F46E5] text-[#4F46E5] rounded-md py-2 px-3 text-sm font-medium shadow hover:border-[#5946e5] button-shadow"
                onClick={() => setOpenModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`w-full border-[1px] text-white border-[#4F46E5] bg-[#4F46E5]
                ${mailLoading ? 'opacity-80' : 'opacity-100'}
                rounded-md py-2 px-3 text-sm font-medium shadow hover:bg-[#5946e5] button-shadow`}
              >
                {mailLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}

export default connector(ContactFormModal);
